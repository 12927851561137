<template>
  <div>
    <!-- //第一个页面 -->
    <div v-if="switc == 1">
      <el-card>
        <el-form :inline="true" class="demo-form-inline">

          <el-form-item label="项目名称:">
            <el-input placeholder="请输入项目名称" v-model="pro_name"></el-input>
          </el-form-item>

          <el-form-item label="自评分:">
            <el-select v-model="num" placeholder="全部">
              <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">查询</el-button>
            <el-button type="warning" @click="reset">重置</el-button>
          </el-form-item>
        </el-form>
        <div class="top">
          <ol>
            <li><span></span></li>
            <li>
              <!-- <el-button type="success" size="mini">
            双重预评估企业标准表下载</el-button> -->
              <a href="http://www.aqjkpt.com:9999/266049f3-6af4-4f46-9c79-1e2ebc3c23fd.docx"
                class="download">双重预评估企业标准表下载</a>
              <el-button type="success" size="mini" @click="exportExcel" v-if="but == 1">导出报表</el-button>
              <el-button type="success" size="mini" v-if="but2 == 1" @click="exportExcel2">导出报表</el-button>
            </li>
          </ol>
        </div>
        <!-- //标签页 -->
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="项目" name="1">
            <!-- //表格 -->
            <el-table :data="tableData" stripe style="width: 100%" :header-cell-style="{
              background: '#4d7cfe',
              color: '#ffffff',
              textAlign: 'center',
            }">
              <el-table-column prop="name" label="项目名称" align="center">
              </el-table-column>
              <el-table-column prop="engineering" label="工程性质" align="center">
              </el-table-column>
              <el-table-column prop="site" label="所属区域" align="center">
              </el-table-column>
              <el-table-column prop="corporations" label="所属公司" align="center">
              </el-table-column>
              <el-table-column prop="self_count" label="自评次数" align="center">
              </el-table-column>
              <el-table-column prop="Self_evaluate_data.evaluate_grade" label="最新自评分" align="center">
              </el-table-column>
              <el-table-column prop="Self_evaluate_data.operator" label="操作人" align="center">
              </el-table-column>
              <el-table-column prop="Self_evaluate_data.phone" label="联系方式" align="center">
              </el-table-column>
              <el-table-column prop="Self_evaluate_data.last_add_time" label="最后提交时间" align="center" width="160px;">
              </el-table-column>
              <el-table-column prop="system_evaluation" label="系统评估" align="center">
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-link type="primary" :underline="false" @click="query(scope.row)">查看详情</el-link>
                  <a :href="scope.row.Self_evaluate_data.details_url" v-if="scope.row.Self_evaluate_data.details_url"
                    style="margin-left: 20px; text-decoration: none">查看</a>
                </template>
              </el-table-column>
            </el-table>
            <!-- //分页 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
              :page-sizes="[2, 5, 10, 20]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper"
              :total="total">
            </el-pagination>
          </el-tab-pane>
          <!-- //企业 -->
          <el-tab-pane label="企业" name="2" v-if="rank">
            <!-- day2 -->
            <el-table :data="tableData4" stripe style="width: 100%" :header-cell-style="{
              background: '#4d7cfe',
              color: '#ffffff',
              textAlign: 'center',
            }">
              <el-table-column prop="name" label="企业名称" align="center">
              </el-table-column>
              <el-table-column prop="business" label="企业资质" align="center">
              </el-table-column>
              <el-table-column prop="address" label="注册地址" align="center">
              </el-table-column>
              <el-table-column prop="com_self_evaluate_count" label="自评次数" align="center">
              </el-table-column>
              <el-table-column prop="com_self_evaluate_data.evaluate_grade" label="最新自评分" align="center">
              </el-table-column>
              <el-table-column prop="com_self_evaluate_data.evaluator" label="操作人" align="center">
              </el-table-column>
              <el-table-column prop="com_self_evaluate_data.phone" label="联系方式" align="center">
              </el-table-column>
              <el-table-column prop="com_self_evaluate_data.last_add_time" label="最后提交时间" align="center">
              </el-table-column>
              <el-table-column prop="fir_evaluation" label="系统评估" align="center">
              </el-table-column>
              <el-table-column prop="" label="操作" align="center">
                <template slot-scope="scope">
                  <el-link type="primary" :underline="false" @click="enterprisequery(scope.row)">查看详情</el-link>
                </template>
              </el-table-column>
            </el-table>
            <!-- //分页 -->
            <el-pagination @size-change="handleSizeChange3" @current-change="handleCurrentChange3" :current-page="page3"
              :page-sizes="[5, 10, 20]" :page-size="pagesize3" 
              :total="total3">
            </el-pagination>
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </div>
    <!-- //第二个页面 -->
    <div v-else-if="switc == 2">
      <!-- 项目页面 -->
      <el-card v-if="flag">
        <el-button type="primary" size="mini" @click="previous" round>返回上一级</el-button>
        <div class="topp">基本信息</div>
        <el-card>
          <div class="left">
            <p>
              项目名称 :<span>{{ top.name }}</span>
            </p>
            <p>
              所属公司 :<span>{{ top.corporations }}</span>
            </p>
            <p>
              所属区域 : <span>{{ top.site }}</span>
            </p>
            <p>
              工程性质 :<span>{{ top.engineering }}</span>
            </p>
            <!-- <p>
            最后更新时间 :<span>{{ top.Self_evaluate_data.last_add_time }}</span>
          </p> -->
          </div>
        </el-card>
        <!-- 表单下 -->
        <el-card v-for="item in tableData2" :key="item.index">
          <div class="yuan">
            <span>{{ item.evaluate_grade }}</span>
          </div>
          <div class="con">
            <p>
              自评结果:<span v-if="item.evaluate_grade < 80 ? '合格' : '不合格'">{{ item.evaluate_grade }}</span>
            </p>
            <p>
              操作人:<span>{{ item.operator }}</span>
            </p>
            <p>
              上传时间:<span>{{ item.create_time }}</span>
            </p>
          </div>
        </el-card>
      </el-card>
      <!-- 企业页面 -->
      <el-card v-else>
        <el-button type="primary" size="mini" @click="previous" round>返回上一级</el-button>
        <div class="topp">基本信息</div>
        <el-card>
          <div class="left">
            <p>
              企业名称 :<span>{{ enterpriseData_top.name }}</span>
            </p>
            <p>
              企业资质 : <span>{{ enterpriseData_top.business }}</span>
            </p>
            <p>
              注册地址 : <span>{{ enterpriseData_top.address }}</span>
            </p>
          </div>
        </el-card>
        <!-- 表单下 -->
        <el-card v-for="item in enterpriseData" :key="item.index">
          <div class="yuan">
            <span>{{ item.evaluate_grade }}</span>
          </div>
          <div class="con">
            <p>
              自评结果:
              <span v-if="item.evaluate_grade < 80 ? '合格' : '不合格'">{{
                item.evaluate_grade
              }}</span>
            </p>
            <p>
              操作人: <span>{{ item.evaluator }}</span>
            </p>
            <p>
              上传时间: <span>{{ item.create_time }}</span>
            </p>
          </div>
        </el-card>
      </el-card>
    </div>
  </div>
</template>
<script>
import {
  addassessmententerrecords,
  addassessmententerdetails,
  addassessmententerenterprise,
  addassessmententerenterprise1,
} from "../../../request/assessment";
export default {
  name: "",
  props: [],
  data() {
    return {
      rank: '',
      tableData: [],
      tableData2: [],
      tableData4: [],
      enterpriseData: [],
      activeName: "1",
      switc: 1, //表格切换
      page: 1,
      pagesize: 10,
      page3: 1,
      pagesize3: 10,
      total: 0,
      total3: 0,
      options: [
        {
          value: "0",
          label: "分数从高到低",
        },
        {
          value: "1",
          label: "分数从低到高",
        },
      ],
      options1: [
        {
          value: "80",
          label: "80分以下",
        },
        {
          value: "70",
          label: "70分以下",
        },
        {
          value: "60",
          label: "60分以下",
        },
        {
          value: "50",
          label: "50分以下",
        },
        {
          value: "40",
          label: "40分以下",
        },
        {
          value: "30",
          label: "30分以下",
        },
        {
          value: "20",
          label: "20分以下",
        },
      ],
      pro_name: "", //项目名
      name: "", //企业名
      num: "", //查询多少分,
      lang: "", //排序
      top: [],
      enterpriseData_top: [], //企业
      id: "", //id
      flag: true, //判断是项目还是企业
      enterpriseData_id: "", //存放企业id
      but: 1, //导出按钮显示或隐藏
      but2: 2, //导出按钮显示或隐藏
      pjtid: '',

    };
  },

  watch: {
    $route() {
      this.pjtid = this.$route.query.id
    },
    pjtid() {
      this.switc = 1;
      this.rank = localStorage.getItem("rank") == 0 || localStorage.getItem("rank") == 1 ? true : false
      this.shuju();
    },
  },
  methods: {
    //项目
    async shuju() {
      let msg = {
        page: this.page,
        size: this.pagesize,
        num: this.num,
        name: this.pro_name,
        unique_identification: localStorage.getItem("uid"),
      };
      if (localStorage.getItem('isdealer') == 'true') {
        if (localStorage.getItem('type') == 'pro') {
          msg.pro_id = localStorage.getItem('project')
        } else if (localStorage.getItem('type') == 'firm') {
          msg.firm_id = localStorage.getItem('project')
        }
      }

      const res = await addassessmententerrecords(msg);
      this.total = res.data.count;
      this.tableData = res.data.data;
      for (var i = 0; i < this.tableData.length; i++) {
        this.tableData[i].Self_evaluate_data.last_add_time =
          this.tableData[i].Self_evaluate_data.last_add_time.split(".")[0];
      }
      // console.log(res,159)
    },
    //企业
    async shuju2() {
      let msg = {
        page: this.page3,
        size: this.pagesize3,
        com_name: localStorage.getItem('project') + localStorage.getItem('type'),
        com_grade: this.num,
        name: this.pro_name,
      };
      const res = await addassessmententerenterprise(msg);
      // console.log(res, '企业');
      this.total3 = res.data.count;
      this.tableData4 = res.data.msg;
      // console.log(this.tableData4,147)
      for (var i = 0; i < this.tableData4.length; i++) {
        this.tableData4[i].com_self_evaluate_data.last_add_time =
          this.tableData4[i].com_self_evaluate_data.last_add_time.split(".")[0];
      }
    },
    //企业
    async shuju3() {
      let msg = {
        page: this.page3,
        size: this.pagesize3,
        num: this.num,
        pro_name: this.pro_name,
        unique_identification: localStorage.getItem("uid"),
      };
      const res = await addassessmententerenterprise1(msg);
      // console.log(res, '企业');
      this.total3 = res.data.count;
      this.tableData4 = res.data.data;
      // console.log(this.tableData4,147)
      for (var i = 0; i < this.tableData4.length; i++) {
        this.tableData4[i].com_self_evaluate_data.last_add_time =
          this.tableData4[i].com_self_evaluate_data.last_add_time.split(".")[0];
      }
    },
    //分页
    handleSizeChange(pagesize) {
      // console.log(`每页 ${pagesize} 条`);
      this.pagesize = pagesize;
      this.shuju();
    },
    handleCurrentChange(page) {
      // console.log(`当前页: ${page}`);
      this.page = page;
      this.shuju();
    },
    //查询
    onSubmit() {
      this.page = 1
      this.page3 = 1
      if (this.activeName == '1') {
        this.shuju();
      } else {
        if (localStorage.getItem('rank') == '0') {
          this.shuju3();
        } else {
          this.shuju2();
        }
      }
    },
    //重置
    reset() {
      this.num = "";
      this.pro_name = "";
      if (this.activeName == '1') {
        this.shuju();
      } else {
        if (localStorage.getItem('rank') == '0') {
          this.shuju3();
        } else {
          this.shuju2();
        }
      }
    },
    //点击查看项目详情
    async query(row) {
      this.id = row.id;
      // console.log(row,123456)
      this.top = row;
      this.top.Self_evaluate_data.last_add_time = this.$moment(
        this.top.Self_evaluate_data.last_add_time
      ).format("YYYY-MM-DD HH:mm:ss");
      this.switc = 2;
      let msg = {
        pro_com: 0,
        id: row.id,
      };
      const res = await addassessmententerdetails(msg);
      // console.log(res,898989)
      this.tableData2 = res.data.data;
      // console.log(this.tableData2,8520);
      for (var i = 0; i < this.tableData2.length; i++) {
        this.tableData2[i].create_time = this.$moment(
          this.tableData2[i].create_time
        ).format("YYYY-MM-DD HH:mm:ss");
      }
    },
    //点击返回上一级
    previous() {
      this.switc = 1;
      this.shuju();
    },

    handleClick(tab, event) {
      this.pro_name = ''
      this.num = ''
      if (this.activeName == 1) {
        this.but = 1;
        this.but2 = 2;
        this.shuju();
        this.flag = true;
      }
      if (this.activeName == 2) {
        this.but2 = 1;
        this.but = 2;
        if (localStorage.getItem('rank') == '0') {
          this.shuju3();
        } else {
          this.shuju2();
        }
        this.flag = false;
      }
    },
    //企业分页
    handleSizeChange3(page3) {
      // console.log(`每页 ${val} 条`);
      this.pagesize3 = page3;
      if (localStorage.getItem('rank') == '0') {
        this.shuju3();

      } else {
        this.shuju2();

      }
    },
    handleCurrentChange3(pagesize3) {
      // console.log(`当前页: ${val}`);
      this.page3 = pagesize3;
      if (localStorage.getItem('rank') == '0') {
        this.shuju3();

      } else {
        this.shuju2();

      }
    },
    //企业点击查询跳转页面
    async enterprisequery(row) {
      this.enterpriseData_id = row.id;
      this.enterpriseData_top = row;
      this.switc = 2;
      let msg = {
        pro_com: 1,
        id: this.enterpriseData_id,
      };
      const res = await addassessmententerdetails(msg);
      // console.log(res,898989)
      this.enterpriseData = res.data.data;
      // console.log(this.enterpriseData,8520);
      for (var i = 0; i < this.enterpriseData.length; i++) {
        this.enterpriseData[i].create_time = this.$moment(
          this.enterpriseData[i].create_time
        ).format("YYYY-MM-DD HH:mm:ss");
      }
    },
    //导出报表项目
    exportExcel() {
      require.ensure([], () => {
        const {
          export_json_to_excel,
        } = require("../../../vendor/Export2Excel"); //引入文件
        const tHeader = [
          "项目名称",
          "工程性质",
          "所属区域",
          "所属公司",
          "自评次数",
          "最新自评分",
          "操作人",
          "联系方式",
          "最后提交时间",
          "系统评估",
        ]; //将对应的属性名转换成中文 //const tHeader = [];
        const filterVal = [
          "name",
          "engineering",
          "site",
          "corporations",
          "self_count",
          "Self_evaluate_data.evaluate_grade",
          "Self_evaluate_data.operator",
          "Self_evaluate_data.phone",
          "Self_evaluate_data.last_add_time",
          "system_evaluation",
        ]; //table表格中对应的属性名
        console.log(this.tableData,'1111111111111111');

        const list = this.tableData; //想要导出的数据
        const data = this.formatJson(filterVal, list);
        export_json_to_excel(tHeader, data, "列表excel");
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
    //导出报表企业
    exportExcel2() {
      require.ensure([], () => {
        const {
          export_json_to_excel,
        } = require("../../../vendor/Export2Excel"); //引入文件
        const tHeader = [
          "企业名称",
          "企业资质",
          "注册地址",
          "自评次数",
          "最新自评分",
          "操作人",
          "联系方式",
          "最后提交时间",
          "系统评估",
        ]; //将对应的属性名转换成中文 //const tHeader = [];
        const filterVal = [
          "name",
          "business",
          "address",
          "com_self_evaluate_count",
          "com_self_evaluate_data.evaluate_grade",
          "com_self_evaluate_data.evaluator",
          "com_self_evaluate_data.phone",
          "com_self_evaluate_data.last_add_time",
          "fir_evaluation",
        ]; //table表格中对应的属性名
        console.log(this.tableData4,'1111111111111111');

        const list = this.tableData4; //想要导出的数据
        const data = this.formatJson(filterVal, list);
        export_json_to_excel(tHeader, data, "列表excel");
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
  },
  mounted() {
    this.rank = localStorage.getItem("rank") == 0 || localStorage.getItem("rank") == 1 ? true : false
    this.shuju();

  },
  components: {},
  computed: {},
};
</script>

<style lang='less' scoped>
* {
  list-style: none;
}

.top {
  display: flex;
  width: 100%;
  height: 30px;
  line-height: 30px;
  position: relative;
}

.top ol>li {
  float: left;
}

.top ol>li:nth-child(1) {
  color: red;
}

.top ol>li:nth-child(2) {
  position: absolute;
  right: 10px;
}

.left {
  // display: -webkit-flex;
  // display: flex;
  width: 50%;
  float: left;
}

.left p {
  margin: 20px 20px;
  color: red;
}

.left p span {
  color: black;
  font-size: 14px;
  margin-left: 10px;
}

.right {
  // display: flex;
  width: 50%;
  height: 200px;
  // background-color: blue;
  float: left;
  position: relative;
}

.father {
  width: 100px;
  height: 100px;
  // background-color: blue;
  text-align: center;
  position: absolute;
  right: 50px;
  top: 15%;
}

.father p {
  margin-bottom: 10px;
}

.round {
  width: 100px;
  height: 100px;
  background-color: red;
  text-align: center;
  line-height: 100px;
  border-radius: 50%;
}

.round span {
  font-size: 35px;
  color: white;
}

.topp {
  width: 100%;
  height: 40px;
  background-color: blue;
  color: white;
  text-align: center;
  line-height: 40px;
  border-radius: 5px;
  margin-top: 10px;
}

.yuan {
  width: 80px;
  height: 80px;
  // background-color: blue;
  border-radius: 50%;
  border: 1px solid red;
  text-align: center;
  line-height: 80px;
  float: left;
  margin-bottom: 20px;
}

.con {
  width: 600px;
  height: 80px;
  // background-color: yellow;
  float: left;
  margin-bottom: 20px;
  margin-left: 200px;
}

.con p {
  margin-bottom: 10px;
}

.download {
  text-decoration: none;
  padding: 1px 10px;
  background-color: #67c23a;
  color: white;
  float: left;
  margin-right: 10px;
  font-size: 12px;
  border-radius: 5px;
}
</style>
