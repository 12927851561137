//隐患排查
import { request } from "./request"

// 渲染隐患排查数据
export function addassessment(msg){
    return request({
        url: "v3/018/",
        method: "post",
        data: msg,
    })
}
export function addassessmentday2(msg){
    return request({
        url: "api/sys_e/",
        method: "get",
        params:msg
    })
}
// 企业
export function addassessmententerprise(msg){
    return request({
        url: "api/ent_r/",
        method: "get",
        params:msg
    })
}
// 企业
export function addassessmententerprises(msg){
    return request({
        url: "v3/025/",
        method: "get",
        params:msg
    })
}
//自评记录汇总项目奖
export function addassessmententerrecords(msg){
    return request({
        url: "v3/019/",
        method: "get",
        params:msg
    })
}
//项目详情
export function addassessmententerdetails(msg){
    return request({
        url: "v1/evaluate/",
        method: "get",
        params:msg
    })
}

//自评记录汇总项企业

export function addassessmententerenterprise(msg){
    return request({
        url: "v1/com_self_collect/",
        method: "get",
        params:msg
    })
}

export function addassessmententerenterprise1(msg){
    return request({
        url: "v3/026/",
        method: "get",
        params:msg
    })
}



